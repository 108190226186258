"use client";

import { PropsWithChildren, useCallback, useEffect } from "react";
import ym, { YMInitializer } from "react-yandex-metrika";
import { usePathname, useSearchParams } from "next/navigation";

export const enableYM = process.env.NODE_ENV === "production";

export const YandexMetrika = ({ children }: PropsWithChildren) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const hit = useCallback((url: string) => {
    if (enableYM) {
      ym("hit", url);
    } else {
      console.log(`%c[YandexMetrika](HIT)`, `color: orange`, url);
    }
  }, []);

  useEffect(() => {
    const params = searchParams ? searchParams.toString() : "";
    hit(pathname + params);
  }, [hit, pathname, searchParams]);

  return (
    <>
      {enableYM && (
        <YMInitializer
          accounts={[96022689]}
          options={{
            webvisor: true,
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
          }}
          version="2"
        />
      )}
      {children}
    </>
  );
};
